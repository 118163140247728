import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/main.css';
import '../css/main.scss';
import FeaturedArticles from './featured-articles';
import TopTrendingSkills from './top-trending-skills';
import ArticleAside from './article-aside';
import HireAndApply from './hire-and-apply';

const MainWrapper = styled.div`margin: 0;`;
const ContentMarketingStrategy = () => (
	<MainWrapper>
		<main>
			<section className="banner banner--img">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<p className="u-color-black-4 u-weight-bld">LINKEDIN MARKETING</p>
							<h1 className="h1--sm u-color-secondary-alt-3 u-weight-smb u-font-main mt-2 mb-4">
								LinkedIn Content Marketing Strategy<br className="d-none d-lg-block" />
								<span className="d-lg-none">&nbsp;</span>to Improve Conversions
							</h1>
							<p className="p--xl">
								By{' '}
								<span className="u-color-secondary-alt-3 u-weight-smb">
									<a href="https://www.linkedin.com/in/devin-mallonee/">Devin Mallonee</a>
								</span>&nbsp;&nbsp;&nbsp;&nbsp;September 12, 2019&nbsp;&nbsp;&nbsp;&nbsp;249
								shares&nbsp;&nbsp;&nbsp;&nbsp;15 Comments
							</p>
							<StaticImage src="../images/banner-3.jpg" alt="Article banner" className="banner__img" />
						</div>
					</div>
				</div>
			</section>
			<section className="article">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="article__inner">
								<div className="article__main">
									<div className="article__main-intro">
										<p className="p--xhg u-weight-lgt">
											Anyone involved in digital marketing understands the concept that content is
											king and it is how your customers, no matter who they are, notice you and
											recognize your brand. The unfortunate part is that many companies are aware
											of the importance of content marketing but not about the importance of a
											true content strategy.
										</p>
									</div>
									<div className="article__main-content">
										<div className="article__main-content-group">
											<h2 className="h2--md u-weight-bld u-color-black-7 mb-4">
												What is a content Strategy?
											</h2>
											<p>
												A content strategy is a documented and focused plan for the content that
												a company’s posts for their digital marketing. It is a strategic and
												thoughtful exchange of valuable content for real engagement with a
												targeted demographic. One of the most important necessities, when it
												comes to a company’s content strategy, is that they are committed to
												identifying and measuring the value that they gain from their content so
												that they are able to build on that gain.
											</p>
										</div>
										<div className="article__main-content-group">
											<h2 className="h2--md u-weight-bld u-color-black-7 mb-4">
												How does LinkedIn fit into the equation?
											</h2>
											<p>
												Social media is an important part of any content marketing strategy.
												Many people think about Facebook, Twitter, or Instagram when they think
												about social media, and LinkedIn can fall by the wayside. There are over
												570 million companies and professionals that use LinkedIn and they are
												in need of goods and services too. This is why LinkedIn is a critical
												piece of a B2B content marketing strategy.
											</p>
										</div>
										<div className="article__main-content-group">
											<h2 className="h2--md u-weight-bld u-color-black-7 mb-4">
												5 Ways LinkedIn Is Critical in a B2B Content Marketing Strategy
											</h2>
											<p>
												The Content Market Institute has found that LinkedIn is the #1 social
												media platform for helping companies achieve specific goals. There are
												very good reasons for this success.
											</p>
											<ol>
												<li>
													Relationships. Ads and marketing campaigns are great, but when they
													are not focused on a particular demographic they are not as
													successful. He relationships that companies and professionals are
													able to build on LinkedIn are able to bolster real growth and
													engagement with content. When there is a real relationship between 2
													professionals or 2 companies, the content that one sees concerning
													the other means so much more.
												</li>
												<li>
													Research. LinkedIn provides a place for professionals to look at the
													content feeds of their posts and the dialogued opinions that
													employees and companies are having. This insight is valuable
													information in understanding how content is being perceived by
													prospective customers. The insight that LinkedIn can provide within
													a targeted demographic is unparalleled in the world of social media.
												</li>
												<li>
													Targeting. LinkedIn gives companies the ability to target their
													content marketing to the demographics who are in need of their
													product or services. They provide the ability to refine campaigns
													with a wide breadth of filters and parameters so that companies can
													be sure that their content is being seen by those who they are
													hoping to target.{' '}
												</li>
												<li>
													Relevance. There are over 570 million users on LinkedIn, and they
													are all on LinkedIn for professional reasons. At some point, these
													users are going to need to find goods and services that are going to
													be useful for them by either helping them to solve a problem or help
													them to do their job. LinkedIn aids companies in establishing
													themselves as an authority within their niche which adds weight and
													trust behind the content.
												</li>
												<li>
													LinkedIn Analytics. It is hard to improve a strategy if you don’t
													know what is working and what isn’t. LinkedIn Analytics allows
													companies to do just that. They are able to see what campaigns are
													successful and which aren’t. They can see who is engaging with their
													content, how they are engaging, and what images and formats are
													getting the most engagement.
												</li>
											</ol>
										</div>
									</div>
								</div>
								<ArticleAside />
							</div>
						</div>
					</div>
				</div>
			</section>
			<FeaturedArticles />
			<HireAndApply />
			<TopTrendingSkills />
		</main>
	</MainWrapper>
);
export default ContentMarketingStrategy;

import React from 'react'
import Layout from '../components/layout'
import ContentMarketingStrategy from '../components/content-marketing-strategy'
import Seo from '../components/seo'

const ContentStrategy = ({ location }) => (
  <Layout location={location}>
    <ContentMarketingStrategy />
  </Layout>
)

export const Head = ({ location }) => {
  const title = 'LinkedIn Content Marketing Strategy Fundamentals 2020'
  const description =
    'Top LinkedIn Content Marketing Strategy Fundamentals for your 2020 Content Marketing Strategy. Make your LinkedIn Profile Stand out. '

  return <Seo type="page" title={title} description={description} />
}

export default ContentStrategy
